/* ------ new design styles ----- */

body.new-design {
	letter-spacing: 0.8px;
}

/* hide new blocks */
.new-navbar,
.new-hero-section,
.whats-in-the-box,
.how-it-works,
.the-bullymake-guarantee,
.new-monthly-box,
.why-choose-bullymake,
.sponsored-section,
#hero-section.hero-page.hero-in-the-box,
#introduction.introduction-section,
#page-navigation.page-navigation,
#overview-new.overview-section,
#tough-toys.tough-toys,
#tough-toys-extended.tough-toys-extended,
#tasty-treats-new.tasty-treats,
#capture.capture-section,
app-in-the-box .section-item.toys-block,
home-version-a .section-item.toys-block {
	display: none;
}
/* show new blocks */
.new-design .new-navbar,
.new-design .sponsored-section {
	display: flex;
}

.new-design .hero-block,
.new-design #sponsoredIn,
.new-design .initial-monthly-box,
.new-design #whatsInTheBox:not(.whats-in-the-box),
.new-design #howItWorks:not(.how-it-works),
.new-design #theBullymakeGaurantee:not(.the-bullymake-guarantee),
.new-design #toysSlider:not(.toys-block),
.new-design #sponsoredIn:not(.sponsored-section),
.new-design #whyChooseBullymake:not(.why-choose-bullymake),
.new-design #hero-section.hero-page,
.new-design #introduction:not(.introduction-section),
.new-design #page-navigation:not(.page-navigation),
.new-design #overview:not(.overview-section),
.new-design #tough-toys:not(.toys-block),
.new-design #tough-toys-extended:not(.tough-toys-extended),
.new-design #tasty-treats:not(.tasty-treats),
.new-design #capture:not(.capture-section) {
	display: none !important;
}

.new-design .new-hero-section,
.new-design .whats-in-the-box,
.new-design .how-it-works,
.new-design .the-bullymake-guarantee,
.new-design .new-monthly-box,
.new-design .toys-block,
.new-design .why-choose-bullymake,
.new-design #sponsoredIn.sponsored-section,
.new-design #hero-section.hero-page.hero-in-the-box,
.new-design #introduction.introduction-section,
.new-design #page-navigation.page-navigation,
.new-design #overview-new.overview-section,
.new-design #tough-toys-extended.tough-toys-extended,
.new-design #tasty-treats-new.tasty-treats,
.new-design #capture.capture-section {
	display: block!important;
}

/* -------- New Header styles --------*/

/* Header Styles */
.new-design .new-navbar.navbar {
	/* background: #1E1F1F !important; */
	/* border-bottom: solid 7px #000000 !important; */
	z-index: 999;
	position: relative;
	padding: 22px 0 16px 0;
}

.new-design .new-navbar.navbar .site-logo {
	width: 225px;
}

.new-design .new-navbar.navbar.navbar-dark .navbar-nav .nav-link {
	color: #ffffff;
	font-family: 'alternate-gothic';
}

/* Need to set it Default from bootstrap */


.new-design .new-navbar.navbar-dark .navbar-brand {
	position: absolute;
	top: 20px;
}

.new-design .new-navbar.navbar .navbar-toggler .navbar-toggler-icon {
	background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 1%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e")
}

.new-design .new-navbar.navbar .container-fluid {
	max-width: 1340px;
}

.new-design .new-navbar.navbar .btn.btn-white {
	padding-left: 15px;
	padding-right: 15px;
	background: #fff !important;
}

@media screen and (min-width: 1100px) {
	.new-design .new-navbar.navbar-expand-lg .navbar-nav.primary-menu .nav-link {
		padding: 0 20px;
	}
}

/* For Large Screens */
@media screen and (min-width: 992px) {
	.new-design .new-navbar.navbar .primary-menu {
		font-family: 'Oswald', sans-serif;
		text-transform: uppercase;
		font-weight: 300;
		font-size: 1rem;
		margin-left: 30px;
		min-width: 400px;
	}

	.new-design .new-navbar .secondary-menu li {
		font-size: 15px!important;
		position: relative!important;
		font-weight: 300!important;
	}

	.new-design .new-navbar .secondary-menu li:after {
		content: '';
		display: block;
		position: absolute;
		height: 15px;
		width: 1px;
		background: #fff;
		right: 0;
		top: 10px;
	}

	.new-design .new-navbar .secondary-menu li:last-child:after {
		content: none;
	}

	.new-design .new-navbar.navbar .navbar-brand img {
		width: 250px;
	}
}

@media screen and (min-width: 1440px) {
	.new-design .new-navbar.navbar .primary-menu {
		margin-left: 400px!important;
	}
}

@media (min-width: 1200px) and (max-width: 1439px) {
	.new-design .new-navbar.navbar .primary-menu {
		margin-left: 350px!important;
	}
}

@media (min-width: 992px) and (max-width: 1199px) {
	.new-design .new-navbar.navbar .primary-menu {
		margin-left: 320px!important;
	}
}

/* For Small Screens */
@media screen and (max-width: 991px) {
	.new-design .new-navbar.navbar .secondary-menu,
	.new-design .new-navbar.navbar .primary-menu {
		font-family: 'Oswald', sans-serif;
		text-transform: uppercase;
		font-weight: 300;
	}

	.new-design .new-navbar .navbar-collapse {
		margin-top: 80px;
	}

	.new-design .new-navbar .navbar-nav li {
		border-bottom: solid 1px rgba(0, 0, 0, 0.07);
	}

	.new-design .new-navbar .navbar-nav li:last-child {
		border-bottom: none;
	}

	.new-design .new-navbar.navbar-dark .navbar-brand {
		position: absolute;
		left: 20px;
	}

	.new-design .new-navbar.navbar {
		padding-left: 16px;
	}

	.new-design .new-navbar.navbar .btn.btn-white {
		margin-right: 25px;
	}

	.new-design .new-navbar.navbar .navbar-brand img {
		width: 200px;
	}

	.new-design .new-navbar.navbar>.container-fluid {
		justify-content: flex-end;
	}

	.new-design .new-navbar.navbar.btn.btn-white {
		margin-right: 25px;
	}

	.new-design .new-navbar.navbar .navbar-toggler {
		margin-right: 10px;
		border: none;
	}
}

/* For Small Screens */
@media screen and (max-width: 768px) {

	.new-design .new-navbar .site-icon {
		display: none;
	}

	.new-design .new-navbar.navbar .navbar-brand img {
		width: 130px;
	}

	.new-design .new-navbar .navbar-collapse {
		margin-top: 40px;
	}
}

/* --new hero styles */
.new-design .new-hero-section.section-merge {
	background-image: url('/assets/img/new-design-august-hero-bg.jpg') !important;
	background-size: cover;
	/* background-size: 100% 100% !important; */
	background-repeat: no-repeat !important;
	border-bottom: 11px solid #000000 !important;
}

.new-design .new-hero-section.section-merge .section-fg {
	background-image: url("/assets/img/new-design-august-hero-fg.png") !important;
	background-repeat: no-repeat;
	background-position: center 90%;
	width: 100%;
}

@media screen and (min-width: 1400px) {
	.new-design .new-hero-section.section-merge {
		background-position: bottom;
	}
}

@media screen and (min-width: 1600px) {
	.new-design .new-hero-section.section-merge .section-fg {
		background-position: center 100%;
		/* background-size:contain; */
		background-image: url("./assets/img/new-design-august-hero-fg.png");
	}
	.new-design .new-hero-section.section-merge{
		background-size: 100% 100%;
	}
}

@media (min-width: 1450px) and (max-width: 1599px) {
	.new-design .new-hero-section.section-merge .section-fg {
		background-position: center 90%;
		background-size: 100%;
	}
}

@media (min-width: 1200px) and (max-width: 1449px) {
	.new-design .new-hero-section.section-merge .section-fg {
		/* background-position: center 100px; */
		background-size: 100%;
	}
}

@media (min-width: 992px) and (max-width: 1199px) {
	.new-design .new-hero-section.section-merge .section-fg {
		background-position: center 90%;
		background-size: contain;
	}
}

#hero-section .old-design {
	display: block;
}
#hero-section .new-design {
	display: none;
}
.new-design #hero-section .old-design {
	display: none;
}
.new-design #hero-section .new-design {
	display: block;
}

.new-design #hero-section:not(.hero-page) {
	/* padding-top: 80px;
	padding-bottom: 450px;
	color: white; */
}

/* Structural */
.new-design .section-item {
	padding-top: 80px;
	padding-bottom: 80px;
	position: relative;

}

.new-design .section-item.bb {
	border-bottom: solid 15px var(--section-border-bottom);
}

/*-----------------------------------
    Section: Hero
    ---------------------------------*/

.new-design #hero-section:not(.hero-page) {
	background-repeat: no-repeat;
	background-size: cover;
	position: relative;
	z-index: 9;
}

.new-design #hero-section:not(.hero-page) .hero-image {
	margin-top: -80px;
	margin-bottom: -150px;
}

.new-design #hero-section:not(.hero-page) .actions {
	position: relative;
	z-index: 9;
}

/* .new-design #hero-section:not(.hero-page) h3, */
.new-design #hero-section:not(.hero-page) h4 {
	/* font-family: 'alternate-gothic-bold'; */
}

.new-design #hero-section:not(.hero-page) h3,
.new-design #hero-section:not(.hero-page) h1,
.new-design #hero-section:not(.hero-page) h4 {
	color: #343A40;
	/* color: white; */
}

.new-design #hero-section:not(.hero-page) h3 {
	font-size: 26px;
}

.new-design #hero-section:not(.hero-page) h4 {
	font-size: 25px;
	/* margin-top: 20px; */
}

.new-design #hero-section:not(.hero-page) h4 span {
	color: #EB6416;
}

.new-design #hero-section:not(.hero-page) h1 {
	/* font-family: 'dharma-gothic'; */
	font-size: 6em;
	margin-bottom: 0;
}

.new-design #hero-section:not(.hero-page) .btn {
	font-family: 'alternate-gothic';
	font-size: 20px;
}

.new-design .join-section {
	color: #ffffff;
	padding: 0 0 30px 0!important;
	position: absolute!important;
	bottom: 0;
	width: 100%;
	border-bottom: none!important;
}

.new-design .join-section h2 {
	font-family: 'dharma-gothic';
	font-size: 50px;
	line-height: 52px;
}

.new-design .join-section .lead {
	font-family: 'alternate-gothic-bold';
}



/*-----------------------------------
    Section: What's in the Box
    ---------------------------------*/

.new-design #whatsInTheBox {
	/* background-image: url('/assets/img/december-wib.png'); */
	background-repeat: no-repeat;
	background-size: contain;
}

.new-design #whatsInTheBox h2 {
	top: 60px;
	left: 0px;
	right: 0px;
}

.new-design #whatsInTheBox .btn {
	border-radius: 2px;
	font-size: 20px;
	bottom: 130px;
	left: 0px;
	right: 0px;
	max-width: 162px;
	margin: auto;
	padding: 10px;
}

/*-----------------------------------
    Section: How it Works
    ---------------------------------*/

.new-design #howItWorks .column-content {
	padding-left: 3%!important;
	padding-right: 3%!important;
}

.new-design .how-it-works img {
	border-radius: 100%;
	margin-bottom: 60px;
	border: solid 8px #f8f8f8;
}

@media screen and (min-width: 1200px) {
	.new-design #howItWorks img {
		min-height: 210px;
	}
}

.new-design #howItWorks .h3 {
	font-family: 'alternate-gothic-bold';
	font-size: 26px;
	line-height: 30px;
	margin-bottom: 16px;
}

.new-design #howItWorks p {
	font-family: 'alternate-gothic';
	font-size: 18px;
	line-height: 26px;
}

/*-----------------------------------
    Section: Whats in the box
    ---------------------------------*/
.new-design .whats-in-the-box h2 {
	text-transform: uppercase;
}

.new-design .whats-in-the-box .img-sm {
	display: none;
}

/*-----------------------------------
    Section: The Bullymake Gaurantee
    ---------------------------------*/
.new-design .the-bullymake-guarantee .container >.row {
	min-height: 155px;
}

.new-design #theBullymakeGaurantee {
	/* background-image: url('/assets/img/guarantee-banner-bg-2x.jpg'); */
	/* background-color: transparent!important; */
	background-color: var(--bullymake-gurantee-and-sponsored-bg) !important;
	background-repeat: no-repeat;
	background-position: bottom center;
	background-size: cover;
	border-bottom: none;
	height: 220px;
	position: relative;
}

.new-design #theBullymakeGaurantee:after {
	content: '';
	position: absolute;
	left: 0;
	bottom: 0;
	/* background: url('/assets/img/custom-border.png') no-repeat 0 0; */
	background-color: var(--section-border-bottom);

	background-size: cover;
	height: 10px;
	width: 100%;
	z-index: 9;
}

.new-design #theBullymakeGaurantee .row {
	position: relative;
	overflow-x: clip;
}

.new-design #theBullymakeGaurantee .image-holder {
	/* background: url('/assets/img/tbg-dog.png') no-repeat 0 0; */
	background: url('/assets/img/dog-and-sunnies-img.png') no-repeat 0 0;
	background-size: contain;
	position: absolute;
	bottom: -100px;
	right: 0;
	height: 360px;
	max-width: 280px;
	width: 280px;
	z-index: 9;
}

.new-design #theBullymakeGaurantee .row::after {
	content: '';
	background: url('/assets/img/inkblot-banner-monthly.svg') no-repeat 0 0;
	background-size: contain;
	position: absolute;
	right: -58px;
	bottom: -38px;
	height: 210px;
	width: 475px;
	z-index: 0;
}

.new-design #theBullymakeGaurantee .bulldog-thumb {
	filter: none!important;
	-webkit-filter: none!important;
	/* max-width: 180px; */
}

.new-design #theBullymakeGaurantee .column-content a.h6 {
	font-family: 'alternate-gothic-bold';
	border-bottom: 2px solid #ffffff;
	padding-bottom: 5px;
}

.new-design #theBullymakeGaurantee .column-content a.h6:hover,
.new-design #theBullymakeGaurantee .column-content a.h6:focus {
	text-decoration: none;
}

.new-design #theBullymakeGaurantee h3,
.new-design #theBullymakeGaurantee p {
	color: #ffffff;
}

.new-design #theBullymakeGaurantee h3 {
	font-family: 'dharma-gothic';
	font-size: 40px;
	letter-spacing: 1px;
}

.new-design #theBullymakeGaurantee p {
	font-family: 'alternate-gothic-bold';
	font-size: 16px;
	line-height: 20px;
	max-width: 540px;
}

@media screen and (min-width: 769px) {

	.new-design #theBullymakeGaurantee .image-holder.image-holder-mobile,
	.new-design #theBullymakeGaurantee .ink-blot-mobile,
	.new-design .whats-in-the-box .mobile-btn {
		display: none;
	}
}


@media screen and (max-width: 768px) {

	.new-design #theBullymakeGaurantee .image-holder,
	.new-design .whats-in-the-box .desktop-btn,
	.new-design .whats-in-the-box .img-lg {
		display: none;
	}

	.new-design #whatsInTheBox{
		background-image: url("./assets/img/new-design-august-whats-in-a-box.jpg") !important;
		background-color: #F7F9FB !important;
		background-size: contain ;
	}

	.new-design #whatsInTheBox.whats-in-the-box {
		height: 400px!important;
	}

	.new-design #theBullymakeGaurantee .image-holder.image-holder-mobile,
	.new-design .whats-in-the-box .mobile-btn {
		display: block;
	}
}

/*-----------------------------------
    Section: Select a Plan
    ---------------------------------*/
/*
#plans .pricing-item .sub-content {
	min-height: 50px;
}

#plans .pricing-item .badge-holder {
	min-height: 20px;
}

#plans .badge {
	padding: .4em 1.2em;
	font-weight: 500;
	vertical-align: top;
	border-radius: 0rem;
} */

/*-----------------------------------
    Section: Why Choose Bullymake
    ---------------------------------*/
.new-design #whyChooseBullymake {
	background: url('/assets/img/texture-light.jpg') no-repeat 0 0;
	background-size: cover;
	width: 100%;
}

.new-design #whyChooseBullymake .container {
	max-width: 1280px;
	margin: 0 auto;
}

.new-design #whyChooseBullymake .column-content {
	max-width: 325px!important;
	margin: 0 auto;
	display: flex;
	flex-direction: column;
	align-items: center;
}

.new-design #whyChooseBullymake>h2 {
	font-family: 'dharma-gothic';
	font-size: 40px;
	letter-spacing: 0.8px;
	left: 0px;
	right: 0px;
	top: 0px;
}

.new-design #whyChooseBullymake p {
	font-family: 'alternate-gothic-bold';
	font-size: 18px;
	line-height: 26px;
}

.new-design #whyChooseBullymake>h2 span {
	border-bottom: 5px solid #eb5100;
	padding: 12px 33px;
}

.new-design #whyChooseBullymake>h2 img {
	margin: 0 15px;
}

.new-design #whyChooseBullymake .img-wrapper {
	margin-bottom: 30px;
	display: flex;
	align-items: center;
}

.new-design #whyChooseBullymake .img-wrapper .img-info {
	text-align: left;

}


/*-----------------------------------
    Section: sponsoredIn
  ---------------------------------*/
.new-design #sponsoredIn{
	background-color: var(--bullymake-gurantee-and-sponsored-bg) !important;

}
.new-design .sponsored-section {
	/* background: url('/assets/img/banner-seen-in-bg-l.png') no-repeat 0 0; */
	background-size: cover;
	height: 212px;
	position: relative;
}

.new-design .sponsored-section:after {
	content: '';
	position: absolute;
	left: 0;
	bottom: 0;
	/* background: url('/assets/img/custom-border.png') no-repeat 0 0; */
	background-color: var(--section-border-bottom);

	background-size: cover;
	height: 10px;
	width: 100%;
}

.new-design .sponsored-section .container,
.new-design .sponsored-section .row {
	height: 100%;
}

.new-design .sponsored-section .col-sm-12 {
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin: 0 auto;
}


@media screen and (min-width: 1200px) {
	.new-design .sponsored-section .img-container:nth-child(2n) {
		margin: 0 30px;
	}
}

@media screen and (max-width: 768px) {
	.new-design .sponsored-section {
		height: 91px;
	}

	.new-design .sponsored-section::after {
		height: 7px;
	}

	.new-design .sponsored-section .col-sm-12 {
		padding: 0 40px;
	}

	.new-design .sponsored-section .img-container {
		text-align: center;
	}

	.new-design .sponsored-section .img-container:nth-child(2n) {
		margin: 0 40px;
	}
}


/*
.filterImg{
	filter: invert(0.8);
} */

/*-----------------------------------
    Section: Chewers Unite
    ---------------------------------*/

.new-design #cu .top-icon {
	top: 0px;
	left: 0px;
}

.new-design #cu .section-header {
	padding-left: 150px;
}

/* toysSlider */
.new-design #toysSlider {
	margin-bottom: 0px;
}

.new-design .toys-block .section-title-content {
	margin-bottom: 90px;
	max-width: 1140px !important;
	position: relative;
}

.new-design .toys-block .section-blocks {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
}

.new-design .toys-block .section-blocks .block-item {
	margin-bottom: 40px;
	width: 48%;
}

.new-design .toys-block .section-title-content .content-wrapper {
	max-width: 440px;
	margin-left: 50%;
	transform: translateX(-50%);
}

.new-design .toys-block .section-title-content h2,
.new-design .toys-block .section-title-content p {
	color: #343A40
}

.new-design .toys-block .section-title-content h2 {
	font-size: 50px;
}

.new-design .toys-block .section-title-content h2 span {
	color: #FF6C17;
}

.new-design .toys-block .section-title-content p {
	font-family: 'alternate-gothic';
	font-size: 22px;
	line-height: 30px;
}

.new-design .toys-block .section-title-content .img-block {
	position: absolute;
	right: 0;
	top: 0;
}

.new-design .toys-block .section-title-content .img-block img {
	width: 150px;
}

.new-design .toys-block .row-block {
	display: flex;
	justify-content: space-between;
}

.new-design .toys-block .main-block span {
	display: block;
}

.new-design .toys-block .main-block h3,
.new-design .toys-block .main-block p {
	color: #343A40;
}

.new-design .toys-block .main-block h3,
.new-design .toys-block .status-block .status-title span,
.new-design .toys-block .status-block .status-info p {
	font-family: 'dharma-gothic-bold';
}

.new-design .toys-block .main-block h3 {
	font-size: 40px;
	letter-spacing: 1.19px;
	margin-top: 25px;
	text-align: left;
}

.new-design .toys-block .main-block .title-info {
	text-align: left;
	margin: 30px 0 0 20px;
}

.new-design .toys-block .main-block .title-info .tag,
.new-design .toys-block .main-block .sample-item p {
	font-family: 'alternate-gothic';
}

.new-design .toys-block .main-block .title-info .tag {
	color: #C9C9C9;
	font-size: 16px;
	line-height: 24px;
	text-transform: uppercase;
}

.new-design .toys-block .main-block .title-info .subtitle {
	font-family: 'dharma-gothic';
	font-size: 30px;
	line-height: 36px;
	letter-spacing: 0.89px;
}

.new-design .toys-block .main-block .title-info .description {
	font-family: 'alternate-gothic';
	color: #FF6C17;
	font-size: 20px;
	line-height: 24px;
}

.new-design .toys-block .main-block .img-block {
	text-align: center;
	position: absolute;
	bottom: 30px;
	left: 50%;
	transform: translateX(-50%);
}

.new-design .toys-block .main-block .img-block img {
	max-width: 360px;
}

.new-design .toys-block .main-block .sample-item {
	background-color: #343A40;
	position: absolute;
	right: 0;
	bottom: 15px;
	padding: 5px 10px;
}

.new-design .toys-block .main-block .sample-item p {
	color: #FEFEFE;
	font-size: 16px;
	margin: 0;
}

.new-design .toys-block .status-block {
	min-width: 143px;
}

.new-design .toys-block .status-block .status-title {
	background-color: #1E1F1F;
	padding: 8px;
}

.new-design .toys-block .status-block .status-title span,
.new-design .toys-block .status-block .status-info p,
.new-design .toys-block .status-block .status-info p {
	font-size: 26px;
	line-height: 30px;
	letter-spacing: 0.77px;
	text-align: center;
}

.new-design .toys-block .status-block .status-title span {
	color: #C9C9C9;
	font-size: 26px;
	line-height: 28px;
	text-transform: uppercase;
}

.new-design .toys-block .status-block .status-info {
	background-color: #343A40;
	padding: 15px 5px 5px 5px;
}

.new-design .toys-block .status-scheme {
	margin-bottom: 10px;
}

.new-design .toys-block .status-block .status-info p {
	color: #FFFFFF;
	margin: 0 auto;
	max-width: 100px;
	text-align: center;
}

.new-design .toys-block .bottom-block {
	background-color: #666A70;
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.new-design .toys-block .bottom-block .more-info-btn {
	margin-left: 25px;
}

.new-design .toys-block .bottom-block .join-block {
	background-color: #343A40;
	padding: 25px;
	width: 100%;
}

.new-design .toys-block .bottom-block button.btn,
.new-design .toys-block .bottom-block a {
	font-family: 'alternate-gothic-bold';
	font-size: 20px;
}

.new-design .toys-block .bottom-block button.btn {
	text-transform: initial;
}

.new-design .toys-block .bottom-block a {
	position: relative;
}

.new-design .toys-block .bottom-block .more-info-btn a {
	text-transform: initial;
}

.new-design .toys-block .slide-up-block {
	background-color: #ECEEF0;
	position: absolute;
	bottom: 0;
	left: 0;
	padding: 15px 0;
	z-index: 8;
	text-align: left;
	width: 100%;
}

.new-design .toys-block .slide-up-block .category-item {
	padding: 0 40px;
}

.new-design .toys-block .slide-up-block .detail-block:not(:last-child) {
	border-bottom: 1px solid #000000;
	margin-bottom: 10px;
}

.new-design .toys-block .slide-up-block .category-item span {
	font-size: 14px;
	margin-bottom: 5px;
}

.new-design .toys-block .slide-up-block .category-item span,
.new-design .toys-block .slide-up-block .category-item .title {
	font-family: 'alternate-gothic-bold';
}

.new-design .toys-block .slide-up-block .category-item .title {
	margin-bottom: 0;
	font-size: 18px;
}

.new-design .toys-block .slide-up-block .category-item .highlighted-item {
	color: #FF6C17;
	font-family: 'alternate-gothic';
	font-size: 16px;
}

.new-design .toys-block .slide-up-block span {
	color: #666A70;
}

.new-design .toys-block .slide-up-block span,
.new-design .toys-block .slide-up-block .title {
	text-transform: uppercase;
	font-weight: bold;
}

/* toys block cards */
.new-design .toys-block .card-block {
	min-height: 590px;
	perspective: 900px;
}

.new-design .toys-block .main-block {
	background-color: #F8F9FA;
	min-height: 482px;
	padding-left: 30px;
	position: relative;
}

.new-design .toys-block .card-content {
	position: absolute;
	width: 100%;
	height: 100%;

	transition: transform 1.2s;
	transform-style: preserve-3d;
}


.new-design .toys-block .card-block.active .card-content {
	transform: rotateY(180deg);
	transition: transform 1.2s;
}

.new-design .toys-block .front-card,
.new-design .toys-block .back-card {
	position: absolute;
	height: 100%;
	width: 100%;
	text-align: center;
	backface-visibility: hidden;
}

.new-design .toys-block .back-card {
	transform: rotateY(180deg);
}

/* Section4 */
.new-design #testimonialWrapper .section-blocks .block-item {
	width: 150px;
	height: 150px;
	position: relative;
	display: inline-block;
	margin-right: 15px;
	vertical-align: top;
	margin-bottom: 15px;
}

.new-design #testimonialWrapper .block-item.item1 .block-content {
	font-size: 3rem;
	line-height: 1.2;
	margin-top: 25px;
}

.new-design #testimonialWrapper .block-item.item1 .block-content small {
	font-size: 1rem;
}

.new-design #testimonialWrapper .block-item.item1 .block-note {
	position: absolute;
	bottom: 10px;
	left: 0;
	right: 0;
	font-size: .7rem;
}

.new-design #testimonialWrapper .block-item.item2 img {
	width: 80%;
	margin-top: 51px;
	margin-bottom: 10px;
}

.new-design #testimonialWrapper .block-item.item2 span {
	display: block;
	text-shadow: 0 0 black;
	color: #fff;
}

/* small desktop */
@media (min-width:993px) and (max-width: 1199px) {
	.new-design #theBullymakeGaurantee p {
		max-width: 380px;
	}

	.new-design #whatsInTheBox .btn {
		bottom: 70px;
	}
}


/*-------------------------------------------
    Responsive: Small and Extra Small Screen
    -----------------------------------------*/

@media screen and (max-width: 768px) {
	.new-design #hero-section:not(.hero-page) {
		padding-top: 40px;
		text-align: center;
	}

	.new-design #hero-section:not(.hero-page) h3 {
		font-size: 14px;
	}

	.new-design #hero-section:not(.hero-page) h4 {
		font-size: 12px;
		margin-top: 0;
	}

	.new-design #hero-section:not(.hero-page) h1 {
		font-size: 45px;
	}

	.new-design #hero-section:not(.hero-page) .hero-image {
		margin-top: 30px;
		margin-bottom: -120px;
	}

	/* .new-design #hero-section:not(.hero-page) img {
		height: 350px;
		margin: 20px 0;
	} */

	.new-design #hero-section:not(.hero-page) .actions {
		margin-top: 5px !important;
	}

	.new-design #hero-section:not(.hero-page) .actions .btn {
		font-size: 12px;
		padding: 5px 15px;
		font-family: 'alternate-gothic-bold';
		letter-spacing: 0.5px;
		width: 100%;
	}

	.new-design .new-hero-section.section-merge {
		background-image: url("./assets/img/new-design-sm-bg.jpg") !important;
		/* background-size: 100% 100% !important; */
		background-size: cover !important;
		background-position: top;
		padding-top: 0;
	}

	.new-design .new-hero-section.section-merge .section-fg {
		background: none!important;
	}

	.new-design #whatsInTheBox h2 {
		top: 25px;
	}

	.new-design #whatsInTheBox .btn {
		max-width: 120px;
	}

	.new-design #howItWorks {
		padding-bottom: 30px;
	}

	.new-design #howItWorks .column-content {
		margin-bottom: 15px;
		max-width: 400px;
		margin-left: auto;
		margin-right: auto;
	}

	.new-design .carousel .carousel-control-next {
		right: -0px;
	}

	.new-design .carousel .carousel-control-prev {
		left: -0px;
	}

	.new-design #testimonialCarousel .carousel-item {
		padding-left: 0%;
		padding-right: 0%;
	}

	.new-design #cu .section-header {
		padding-left: 116px;
	}

	.new-design #cu {
		padding-bottom: 40px;
	}

	.new-design #testimonialCarousel .indicators img {
		width: 80px;
		border-width: 5px;
	}

	.new-design #testimonialCarousel .carousel-control-prev,
	.new-design #testimonialCarousel .carousel-control-next {
		display: none;
	}

	.new-design #whatsInTheBox {
		background-position: center center;
		background-size: 120% auto;
	}

	.new-design #whatsInTheBox .btn {
		bottom: 30px;
	}

	.new-design #whatsInTheBox .mobile-btn {
		font-size: 14px;
	}
}

/* tablet */
@media (min-width: 769px) and (max-width: 992px) {
	.new-design .new-hero-section.section-merge .section-fg {
		background-position: center center;
	}

	.new-design .new-hero-section #hero-section:not(.hero-page) {
		padding-top: 60px;
	}

	.new-design #theBullymakeGaurantee {
		min-height: 260px;
	}

	.new-design #theBullymakeGaurantee .row {
		position: initial;
	}

	.new-design #theBullymakeGaurantee .inkblot-item {
		bottom: 0;
		right: -125px;
	}

	.new-design #theBullymakeGaurantee .bulldog-thumb {
		/* max-width: 150px; */
	}

	.new-design #theBullymakeGaurantee .image-holder {
		background: url('/assets/img/guarantee-dog.png') no-repeat 0 0;
		bottom: -96px;
		right: 0;
		height: 360px;
		max-width: 220px;
		width: 220px;
		z-index: 9999;
	}

	.new-design #theBullymakeGaurantee .row::after {
		background: url('/assets/img/ink-blot-footer-monthly.svg') no-repeat 0 0;
		right: 60px;
		bottom: -10px;
		height: 200px;
		width: 185px;
	}

	.new-design #theBullymakeGaurantee p {
		font-size: 18px;
		max-width: 420px;
	}

	.new-design #whatsInTheBox .btn {
		bottom: 40px;
	}

	.new-design .toys-block .bottom-block .join-block .btn {
		padding: 12px;
	}

	.new-design .toys-block .main-block {
		min-height: 430px;
	}

	.new-design .toys-block .main-block .img-block img {
		max-width: 280px;
	}
}

/* mobile */
@media screen and (max-width: 768px) {
	.new-design .section-item {
		padding-bottom: 20px;
	}

	.new-design #whatsInTheBox {
		height: auto !important;
	 }

	.new-design .whats-in-the-box .img-sm {
		display: block;
	}

	.new-design #whatsInTheBox .btn {
		padding: 7px 10px;
	}

	.new-design #whyChooseBullymake {
		padding-top: 80px;
	}

	.new-design #whyChooseBullymake .row {
		display: flex;
		flex-direction: column;
		width: 100%;
		margin: 0;
	}

	.new-design #whyChooseBullymake .col-sm-4 {
		flex: 0 0 100%;
		max-width: 100%;
	}

	.new-design #whyChooseBullymake>h2 {
		font-size: 1.4rem;
	}

	.new-design #whyChooseBullymake p {
		font-size: 16px;
		line-height: 22px;
	}

	.new-design #whyChooseBullymake .img-wrapper {
		margin-bottom: 10px;
		justify-content: center;
	}

	.new-design #whyChooseBullymake .col-sm-4 {
		margin-bottom: 15px;
	}

	.new-design #hero-section:not(.hero-page) {
		/* padding-bottom: 0px; */
	}

	.new-design #howItWorks {
		padding: 45px 5px 0 5px;
	}

	.new-design #howItWorks h2 {
		font-size: 30px;
	}

	.new-design #howItWorks img {
		max-width: 118px;
		margin-bottom: 15px;
	}

	.new-design #howItWorks .row .col-6 {
		padding-left: 5px;
		padding-right: 5px;
	}

	.new-design #howItWorks .h3 {
		font-size: 16px;
		line-height: 30px;
		margin-bottom: 10px;
	}

	.new-design #howItWorks p {
		font-family: 'alternate-gothic-bold';
		font-size: 12px;
		line-height: 18px;
		margin-top: 0 !important;
		max-width: 128px;
		margin: 0 auto;
	}

	.new-design #howItWorks .row {
		padding-top: 20px !important;
	}

	.new-design .join-section {
		position: relative!important;
	}



	.new-design #join-section h2 {
		font-size: 30px !important;
		line-height: 32px;
		margin-bottom: 0;
	}

	.new-design #join-section p {
		font-size: 14px;
		margin-bottom: 0;
	}

	.new-design .toys-block {
		padding-top: 50px;
	}

	.new-design .toys-block .section-blocks .block-item {
		width: 100%!important;
	}

	.new-design .toys-block .toy-header h3 {
		font-family: 'dharma-gothic'!important;
		font-size: 50px!important;
		line-height: 54px;
		margin: 0;
	}

	.new-design .toys-block .toy-header p.h5 {
		font-family: 'alternate-gothic-bold' !important;
		font-size: 21px!important;
	}

	.new-design .toys-block .carousel-item p,
	.new-design .toys-block .carousel-item h5 {
		font-family: 'alternate-gothic-bold'!important;
		margin-bottom: 0;
	}

	.new-design .toys-block .carousel-item p.h4 {
		font-size: 26px !important;
	}

	.new-design .toys-block .toy-header img,
	.new-design .toys-block .carousel-item img {
		max-height: 210px;
	}

	.new-design #theBullymakeGaurantee {
		height: 260px;
		overflow: hidden;
	}

	.new-design #theBullymakeGaurantee .container,
	.new-design #theBullymakeGaurantee .row {
		justify-content: flex-start;
	}

	.new-design #theBullymakeGaurantee .bulldog-img {
		display: none;
	}

	.new-design #theBullymakeGaurantee .col-sm-7 {
		max-width: 100%;
		flex: 0 0 100%;
		z-index: 2;
	}

	.new-design #theBullymakeGaurantee h3 {
		font-size: 30px;
	}

	.new-design #theBullymakeGaurantee p {
		font-size: 14px;
		line-height: 20px;
		max-width: 280px;
		margin-bottom: 30px;
	}

	.new-design #theBullymakeGaurantee .column-content {
		text-align: left !important;
		margin-top: 0 !important;
	}

	.new-design #theBullymakeGaurantee .image-holder {
		bottom: -22px;
		right: 0;
		height: 160px;
		max-width: 100%;
		width: 140px;
	}

	.new-design #theBullymakeGaurantee .row::after {
		background: none;
	}

	.new-design #theBullymakeGaurantee .ink-blot-mobile {
		position: relative;
	}

	.new-design #theBullymakeGaurantee .ink-blot-mobile::before {
		content: '';
		background: url('/assets/img/ink-blot-footer-monthly.svg') no-repeat 0 0;
		position: absolute;
		height: 170px;
		width: 215px;
		right: 0;
		bottom: -70px;
	}

	.new-design #theBullymakeGaurantee .inkblot-item-mobile {
		display: block;
		position: absolute;
		right: -18vw;
		bottom: -10px;
		width: 240px;
	}

	.new-design .toys-block .section-title-content {
		margin-bottom: 30px;
	}

	.new-design .toys-block .section-title-content h2 {
		font-size: 30px;
		margin-top: 0 !important;
	}

	.new-design .toys-block .section-title-content p {
		font-size: 14px;
		line-height: 20px;
		max-width: 305px;
		margin: 0 auto 20px auto;
	}

	.new-design .toys-block .section-title-content .img-block {
		position: relative;
	}

	.new-design .toys-block .section-title-content .img-block img {
		width: 100px;
	}

	.new-design .toys-block .section-title-content .content-wrapper {
		width: 100%;
		max-width: 100%;
	}

	.new-design .toys-block .block-item:last-child .main-block .img-block {
		bottom: 80px;
	}

	.new-design .toys-block .card-block {
		min-height: 452px;
	}

	.new-design .toys-block .main-block {
		min-height: 360px;
	}

	.new-design .toys-block .main-block h3 {
		font-size: 30px;
	}

	.new-design .toys-block .status-block {
		min-width: 110px;
	}

	.new-design .toys-block .status-block .status-title span,
	.new-design .toys-block .status-block .status-info p {
		line-height: 20px;
	}

	.new-design .toys-block .status-block .status-title span {
		font-size: 14px;
	}

	.new-design .toys-block .status-block .status-info p,
	.new-design .toys-block .main-block .title-info .description {
		font-size: 16px;
	}

	.new-design .toys-block .main-block .title-info .subtitle {
		font-size: 25px;
	}

	.new-design .toys-block .main-block .img-block img {
		max-width: 320px;
	}

	.new-design .toys-block .btn-more-info {
		text-transform: initial;
	}

	.new-design .toys-block .bottom-block button.btn,
	.new-design .toys-block .bottom-block a {
		font-size: 12px;
		padding: 5px 18px;
	}

	.new-design .toys-block .bottom-block .join-block {
		padding: 15px 23px;
	}

	.new-design .toys-block .bottom-block .more-info-btn {
		margin-left: 23px;
	}
}

@media screen and (max-width: 400px) {
	.new-design #whatsInTheBox {
		height: 360px!important;
		background: url('/assets/img/whats-in-the-box-phone.png') no-repeat top center;
	}
}


@media screen and (max-width: 992px) {
	.new-design #theBullymakeGaurantee .column-content a.h6 {
		font-family: 'alternate-gothic';
		font-size: 18px;
		letter-spacing: 1px;
	}

	.new-design .new-hero-section.section-merge .section-fg {
		background-size: 100%;
		background-position: center 80%;
	}
}


/* new global styles fixes - applied only for new-design */
.new-design .carousel-control-next-icon,
.new-design .carousel-control-prev-icon {
	height: 37px;
}

.new-design .carousel-control-next-icon {
	background-image: url("data:image/svg+xml,%3Csvg width='22' height='38' viewBox='0 0 22 38' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cmask id='mask0_0_40369' style='mask-type:luminance' maskUnits='userSpaceOnUse' x='0' y='0' width='22' height='38'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M0.643738 0.356201H21.6437V37.3562H0.643738V0.356201Z' fill='white'/%3E%3C/mask%3E%3Cg mask='url(%23mask0_0_40369)'%3E%3Cpath opacity='0.25' fill-rule='evenodd' clip-rule='evenodd' d='M3.39809 37.3562C2.69306 37.3562 1.98873 37.0832 1.45032 36.538C0.374878 35.4482 0.374878 33.6792 1.45032 32.5894L14.9945 18.856L1.45032 5.12331C0.374878 4.03285 0.374878 2.26451 1.45032 1.17405C2.52645 0.0835853 4.26973 0.0835853 5.34586 1.17405L20.8372 16.8817C21.9126 17.9722 21.9126 19.7405 20.8372 20.831L5.34586 36.538C4.80745 37.0832 4.10312 37.3562 3.39809 37.3562' fill='%23382615'/%3E%3C/g%3E%3C/svg%3E%0A");
}

.new-design .carousel-control-prev-icon {
	background-image: url("data:image/svg+xml,%3Csvg width='22' height='38' viewBox='0 0 22 38' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cmask id='mask0_0_39547' style='mask-type:luminance' maskUnits='userSpaceOnUse' x='0' y='0' width='22' height='38'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M21.5747 37.575H0.574707L0.574707 0.574951H21.5747L21.5747 37.575Z' fill='white'/%3E%3C/mask%3E%3Cg mask='url(%23mask0_0_39547)'%3E%3Cpath opacity='0.25' fill-rule='evenodd' clip-rule='evenodd' d='M18.8204 0.574951C19.5254 0.574951 20.2297 0.847915 20.7681 1.39315C21.8436 2.48291 21.8436 4.25195 20.7681 5.34171L7.22392 19.0751L20.7681 32.8078C21.8436 33.8983 21.8436 35.6666 20.7681 36.7571C19.692 37.8476 17.9487 37.8476 16.8726 36.7571L1.38129 21.0494C0.305846 19.9589 0.305846 18.1906 1.38129 17.1001L16.8726 1.39315C17.411 0.847915 18.1153 0.574951 18.8204 0.574951' fill='%23382615'/%3E%3C/g%3E%3C/svg%3E%0A");
}

.new-design .new-monthly-box .monthly-box .carousel-wrapper.style-1 .carousel .carousel-control-next,
.new-design .new-monthly-box .monthly-box .carousel-wrapper.style-1 .carousel .carousel-control-prev {
	background: #ffffff;
}

@media (min-width: 768px) and (max-width: 1300px) {
	.new-design #cu .carousel-wrapper.style-1 .carousel .carousel-control-next,
	.new-design #chewers-unite .carousel-wrapper.style-1 .carousel .carousel-control-next {
		right: 0;
	}

	.new-design #cu .carousel-wrapper.style-1 .carousel .carousel-control-prev,
	.new-design #chewers-unite .carousel-wrapper.style-1 .carousel .carousel-control-prev {
		left: 0;
	}

	.new-design #cu .carousel-wrapper.style-1 .carousel .carousel-control-next,
	.new-design #cu .carousel-wrapper.style-1 .carousel .carousel-control-prev,
	.new-design #chewers-unite .carousel-wrapper.style-1 .carousel .carousel-control-next,
	.new-design #chewers-unite .carousel-wrapper.style-1 .carousel .carousel-control-prev {
		height: 150px;
	}
}

@media (min-width: 1199px) and (max-width:1280px) {
	.new-design .monthly-box .carousel-wrapper.style-1 .carousel .carousel-control-prev {
		left: 10px;
	}

	.new-design .monthly-box .carousel-wrapper.style-1 .carousel .carousel-control-next {
		right: 10px;
	}

	.new-design #testimonialCarousel .carousel-wrapper.style-1 .carousel .carousel-control-prev,
	#instagramCarousel .carousel-wrapper.style-1 .carousel .carousel-control-prev {
		left: 0 !important;
	}

	.new-design #testimonialCarousel .carousel-wrapper.style-1 .carousel .carousel-control-next,
	.new-design #instagramCarousel .carousel-wrapper.style-1 .carousel .carousel-control-next {
		right: 0 !important;
	}
}

@media screen and (max-width: 768px) {
	.new-design .monthly-box .carousel-wrapper .carousel-inner {
		width: 250px;
		margin: 0 auto;
	}

	.new-design .monthly-box .carousel-wrapper.style-1 .carousel .carousel-control-next,
	.new-design .monthly-box .carousel-wrapper.style-1 .carousel .carousel-control-prev {
		width: 30px;
		height: 100px;
	}

	.new-design .monthly-box .carousel-control-next-icon,
	.new-design .monthly-box .carousel-control-prev-icon {
		width: 12px;
	}
}

@media (min-width:475px) and (max-width: 768px) {
	.new-design #inTheBoxDogToys .carousel-wrapper.style-1 .carousel .carousel-control-next {
		right: -25px;
	}

	.new-design #inTheBoxDogToys .carousel-wrapper.style-1 .carousel .carousel-control-prev {
		left: -25px;
	}

	.new-design #inTheBoxDogToys .carousel-wrapper.style-1 .carousel .carousel-inner .carousel-item {
		padding-bottom: 0px !important;
	}
}

@media (max-width:474px) {
	.new-design #inTheBoxDogToys .carousel img {
		max-width: 70%;
	}

	.new-design #inTheBoxDogToys .carousel-wrapper.style-1 .carousel .carousel-control-next {
		right: 10px;
	}

	.new-design #inTheBoxDogToys .carousel-wrapper.style-1 .carousel .carousel-control-prev {
		left: 10px;
	}
}


@media (min-width: 768px) and (max-width: 1300px) {
	.new-design #testimonialCarousel.carousel-wrapper.style-1 .carousel .carousel-control-next {
		right: 0;
	}

	.new-design #testimonialCarousel.carousel-wrapper.style-1 .carousel .carousel-control-prev {
		left: 0;
	}
}


/* new monthly box design */
.new-design .new-monthly-box .monthly-box {
	background: url('/assets/img/texture-themed-boxes.png') no-repeat top center;
	background-size: cover;
	padding: 100px 0 70px 0;
	margin: 0 !important;
}

.new-design .new-monthly-box .monthly-box .column-right>p {
	font-family: 'alternate-gothic-bold';
	font-size: 20px;
	line-height: 24px;
	margin: 0 0 20px 15px;
}

.new-design .new-monthly-box .monthly-box .img-block img {
	min-height: 440px;
}

.new-design .new-monthly-box .monthly-box .column-left h3 {
	font-size: 50px;
	max-width: 290px;
}

.new-design .new-monthly-box .monthly-box .column-left p {
	font-family: 'alternate-gothic-bold';
	font-size: 18px;
	line-height: 24px;
}

.new-design .new-monthly-box .monthly-box .btn {
	font-family: 'alternate-gothic-bold';
	font-size: 20px;
}

.new-design .new-monthly-box .monthly-box .box-title {
	background-color: var(--section-border-bottom);
	padding: 18px;
	text-align: center;
	position: absolute;
	bottom: 0;
	left: 0;
	right: 0;
}

.new-design .new-monthly-box .monthly-box .box-title p {
	font-family: 'alternate-gothic-bold';
	color: #ffffff;
	font-size: 26px;
	margin: 0;
	text-transform: uppercase;
}

@media screen and (min-width:1200px) {
	.new-design .new-monthly-box .monthly-box .img-block {
		height: 520px;
	}
}

@media (min-width: 769px) and (max-width: 991px) {
	.new-design .new-monthly-box .monthly-box .column-content {
		width: 450px;
		text-align: center;
		margin: 0 auto;
	}

	.new-design .new-monthly-box .monthly-box .column-left h3,
	.new-design .new-monthly-box .monthly-box .column-left {
		max-width: 100%;
	}

	.new-design .new-monthly-box .monthly-box .column-left {
		margin: 0 auto 30px auto;
	}

	.new-design .new-monthly-box .monthly-box .col-lg-7 {
		max-width: 530px;
		margin: 0 auto;
	}

	.new-design .new-monthly-box .monthly-box .img-block img {
		min-height: 400px;
	}
}

@media (min-width: 993px) and (max-width: 1199px) {
	.new-design .new-monthly-box .monthly-box .img-block {
		height: 420px;
	}

	.new-design .new-monthly-box .monthly-box .img-block img {
		height: 370px;
		min-height: auto;
	}

	.new-design .new-monthly-box .monthly-box .column-left p {
		max-width: 230px;
	}
}

@media screen and (max-width: 768px) {
	.new-design .new-monthly-box .monthly-box {
		padding: 60px 0 40px 0;
	}

	.new-design .new-monthly-box .monthly-box .column-left h3 {
		font-size: 30px;
		margin-top: 0 !important;
		max-width: 100%;
	}

	.new-design .new-monthly-box .monthly-box .column-left p {
		font-size: 14px;
		line-height: 20px;
	}

	.new-design .new-monthly-box .monthly-box .img-block img {
		min-height: 180px;
		padding-bottom: 30px;
	}

	.new-design .new-monthly-box .monthly-box .box-title {
		padding: 5px;
	}

	.new-design .new-monthly-box .monthly-box .box-title p {
		font-size: 16px;
	}

	.new-design .new-monthly-box .monthly-box .btn {
		font-size: 12px;
		padding: 4px 20px;
		margin-top: 0 !important;
	}
}

/* new footer design styles */
.new-design #footer {
	background: url('https://bullymake-assets.sfo3.cdn.digitaloceanspaces.com/uploads/2024/06/texture-footer-l-665b0bd526e96.webp') no-repeat center center!important;
	background-size: cover !important;
	padding-top: 40px;
	padding-bottom: 40px;
	position: relative;
}

.new-design #footer:after {
	content: '';
	position: absolute;
	bottom: 0;
	right: 0;
	background: url('https://bullymake-assets.sfo3.cdn.digitaloceanspaces.com/uploads/2024/06/ink-blot-footer.svg') no-repeat 0 0;
	width: 270px;
	height: 186px;
	background-size: contain;
}

.new-design #footer li,
.new-design #footer .copyright {
	font-family: 'alternate-gothic';
	font-size: 16px;
	letter-spacing: 0.2px;
}

.new-design .footer-nav .menu-item a {
	color: #f8f9fa;
}

.new-design .footer-nav li {
	margin-right: 10px;
	padding-right: 10px;
	position: relative;
}

.new-design .footer-nav li:after {
	background: #ffffff!important;
	top: 7px!important;
}

.new-design .footer-nav li:last-child:after {
	content: none;
}

.new-design .footer-social li a {
	color: #000;
	width: 30px;
	height: 30px;
	display: inline-block;
	border-radius: 30px;
	background: #fff;
	text-align: center;
	line-height: 30px;
	font-size: 1.2em;
	margin-left: 5px;
	margin-right: 5px;
}

.new-design #footer .removal a {
	font-family: 'alternate-gothic';
	color: #C9C9C9;
	font-size: 14px;
	letter-spacing: 0.18px;
}

.new-design #footer .removal span {
	margin: 0 5px;
}

@media screen and (min-width: 1200px) {

	.new-design #footer .footer-nav li,
	.new-design #footer .copyright {
		font-size: 20px;
	}

	.new-design #footer .removal a {
		font-size: 18px;
	}
}

@media screen and (max-width: 1199px) {
	.new-design .footer-nav li:after {
		top: 5px !important;
	}
}

/* tablet */
@media (min-width: 768px) and (max-width: 992px) {
	.new-design #footer:after {
		height: 103px;
		width: 150px;
	}
}

/* mobile */
@media screen and (max-width: 768px) {
	.new-design #footer {
		padding-bottom: 65px;
	}

	.new-design #footer:after {
		height: 69px;
		width: 100px;
	}
}


/* ------------------------------------------------------------------------------- */
/* ----------------------Whats in the box page new styles------------------------- */
/* button style update */
.new-design .hero-page.hero-in-the-box .btn,
.new-design .introduction-section .btn,
.new-design .page-navigation .btn,
.new-design .capture-section form .button-group a {
	font-family: 'alternate-gothic-bold';
	font-size: 20px;
}

/* end button style update */

.new-design #hero-section.hero-page {
	/* background: url('/assets/img/in-the-box/hero-in-the-box.jpg') no-repeat center center; */
	/* background-size: contain;
	background-size: 100%;
	color: #343A40;
	padding-top: 110px;
	padding-bottom: 80px; */
	background-image: url('/assets/img/in-the-box/wib-hero.jpg');
	background-size: contain;
	background-repeat: no-repeat;
	background-position-y: bottom;
	background-size: 100%;
	padding-top: 100px;
	padding-bottom: 218px;
}

.new-design .hero-page.hero-in-the-box>.container {
	position: relative;
}

.new-design .hero-page.hero-in-the-box h1 {
	color: #343A40;
	font-family: 'dharma-gothic';
	font-size: 70px;
	letter-spacing: 0.8px;
}

.new-design .hero-page.hero-in-the-box .h4 {
	color: #FE6C17;
	display: block;
	font-size: 22px;
	line-height: 40px;
	margin-bottom: 0;
}

.new-design .hero-page.hero-in-the-box .section-content {
	padding-left: 30px;
}

.new-design .hero-page.hero-in-the-box .h4,
.new-design .hero-page.hero-in-the-box h6,
.new-design .hero-page.hero-in-the-box .icon-list li span {
	font-family: 'alternate-gothic-bold';
}

.new-design .hero-page.hero-in-the-box .box-options li {
	font-family: 'alternate-gothic';
	font-size: 18px;
	line-height: 24px;
}

.new-design .hero-page.hero-in-the-box .icon-list>li {
	padding-left: 50px;
}

.new-design .hero-page.hero-in-the-box .icon-list li span {
	font-size: 22px;
}

.new-design .hero-page.hero-in-the-box .icon-list>li>img {
	width: 30px!important;
}

.new-design .hero-page.hero-in-the-box h6,
.new-design .hero-page.hero-in-the-box .icon-list>li,
.new-design .hero-page.hero-in-the-box .icon-list>li p {
	font-size: 18px;
	line-height: 24px;
}

.new-design .hero-page.hero-in-the-box .icon-list>li,
.new-design .hero-page.hero-in-the-box .icon-list>li p {
	font-family: 'alternate-gothic';
}

.new-design .hero-page.hero-in-the-box .image-block {
	position: absolute;
	right: -5vw;
	bottom: -100px;
}

.new-design .hero-page.hero-in-the-box .btn {
	font-size: 20px;
}

.new-design #introduction {
	padding-top: 100px;
	padding-bottom: 100px;
	position: relative;
}

.new-design .introduction-section h2 {
	font-family: 'dharma-gothic';
	font-size: 50px;
	letter-spacing: 0.8px;
}

.new-design .introduction-section p {
	font-family: 'alternate-gothic';
	font-size: 18px;
	line-height: 24px;
}

.new-design .introduction-section .orange-btn {
	margin-right: 25px;
}

.new-design #introduction .lead {
	color: #343A40;
	margin-bottom: 100px;
}

.new-design #introduction .container {
	max-width: 575px!important;
}

.new-design #introduction .container.container-img {
	max-width: 1440px!important;
	position: relative;
}

.new-design #introduction .dog-left,
.new-design #introduction .dog-right {
	position: absolute;
	z-index: 9;
}

.new-design #introduction .dog-left {
	left: -12vw !important;
	bottom: -120px !important;
	height: 540px;
	width: auto !important;
	/* left: -22vw !important;
	bottom: -120px !important;
	height: auto;
	width: 40% !important; */
}

.new-design #introduction .dog-right {
	right: 3%!important;
	bottom: -115px!important;
	width: 341px!important;
}

@media (min-width: 1450px) and (max-width: 1599px) {
	.new-design #introduction .dog-left {
		height: 500px !important;
	}

	.new-design #introduction .dog-right {
		width: 260px !important;
	}
}

@media (min-width: 1200px) and (max-width: 1449px) {
	.new-design #introduction .dog-left {
		height: 360px !important;
	}

	.new-design #introduction .dog-right {
		width: 230px !important;
	}
}

@media (min-width: 992px) and (max-width: 1199px) {
	.new-design #introduction .dog-left {
		height: 290px !important;
	}

	.new-design #introduction .dog-right {
		width: 190px !important;
	}
}

@media (min-width: 768px) and (max-width: 992px) {
	.new-design #introduction .dog-left {
		height: 230px !important;
	}
}

.new-design #page-navigation {
	border-bottom: solid 11px #000000!important;
	padding-top: 0px;
	padding-bottom: 0px;
}

.new-design #page-navigation.bg-green {
	background-color: #1E1F1F !important;
}

.new-design #page-navigation .section-nav .active {
	background: #000000!important;
}

.new-design #page-navigation .section-nav li:not(.active) {
	opacity: 0.6;
}

.new-design #page-navigation .section-nav {
	width: 100%;
}

.new-design #page-navigation .section-nav>li {
	padding: 35px 45px;
	margin: 0px;
}

.new-design #page-navigation .section-nav>li a {
	color: #A7A7A7;
	font-family: 'dharma-gothic-bold';
	font-size: 30px;
	letter-spacing: 0.8px;
	text-decoration: none;
}

.new-design #page-navigation .section-nav>li.active a {
	color: #ffffff;
}

.new-design .page-navigation .btn {
	padding: 10px 30px;
	text-transform: uppercase;
}

.new-design #page-navigation .select-block {
	display: none;
}

.new-design #page-navigation #button-basic {
	font-family: 'dharma-gothic';
	font-size: 18px;
	letter-spacing: 0.5px;
	text-decoration: none;
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 130px;
}

.new-design #page-navigation #button-basic span {
	margin-top: 6px;
}

.new-design #page-navigation .dropdown-item {
	font-family: 'alternate-gothic';
}

.new-design #overview-new {
	padding-top: 70px;
	padding-bottom: 70px;
}

.new-design #tough-toys-extended {
	background: url('/assets/img/in-the-box/happy-chewers-bg-l.jpg') no-repeat center center;
	background-size: cover;
	height: 1200px;
	padding-top: 100px!important;
	padding-bottom: 90px!important;
}

.new-design .tough-toys-extended>.container {
	max-width: 1220px;
}

.new-design #tough-toys-extended .section-title {
	font-family: 'dharma-gothic';
	font-size: 50px!important;
	letter-spacing: 0.8px;
}

.new-design #tough-toys-extended .section-content {
	margin-top: -50px;
	position: relative;
}

.new-design #tough-toys-extended .image-frame {
	padding: 20px;
	background: #fff;
}

.new-design #tough-toys-extended .image-frame img {
	height: 280px;
	width: 100%;
}

.new-design #tough-toys-extended .image-frame p {
	font-family: 'alternate-gothic-bold';
	font-size: 18px;
	margin-top: 20px;
	margin-bottom: 0px;
	text-indent: 20px;
}

.new-design #tough-toys-extended .image-frame.rotate-0 {
	transform: scale(1.15)!important;
	z-index: 9;
	position: relative;
	left: -18px;
	top: -220px;
}

.new-design #tough-toys-extended .image-frame.rotate-cw {
	transform: scale(1.1) rotate(5deg)!important;
	position: relative;
}

.new-design #tough-toys-extended .row:first-child .image-frame.rotate-cw {
	top: -40px;
	left: -11px;
}

.new-design #tough-toys-extended .row:last-child .image-frame.rotate-cw {
	top: -210px;
	right: -100px;
}

.new-design #tough-toys-extended .image-frame.rotate-acw {
	transform: scale(1.1) rotate(-5deg)!important;
	position: relative;
}

.new-design #tough-toys-extended .row:first-child .image-frame.rotate-acw {
	top: -60px;
	left: 40px;
}

.new-design #tough-toys-extended .row:last-child .image-frame.rotate-acw {
	top: -210px;
	left: -130px;
}

.new-design #tough-toys-extended .with-star-icon,
.new-design #tough-toys-extended .with-bolt-icon {
	position: relative
}

.new-design #tough-toys-extended .with-star-icon:before {
	content: '';
	background: url('/assets/img/in-the-box/icon-star.png') no-repeat 0 0;
	width: 68px;
	height: 69px;
	position: absolute;
	bottom: -30px;
	right: -30px;
}

.new-design #tough-toys-extended .with-bolt-icon:before {
	content: '';
	background: url('/assets/img/in-the-box/icon-bolt.svg') no-repeat 0 0;
	width: 83px;
	height: 70px;
	position: absolute;
	top: -26px;
	left: -30px;
}

.new-design .tasty-treats-new.section-item {
	padding: 70px 0 90px 0;
}

.new-design #tasty-treats-new .section-title-content.container {
	max-width: 600px;
}

.new-design #tasty-treats-new .section-title-content.container {
	max-width: 600px;
}

.new-design #tasty-treats-new .section-title-content p {
	font-family: 'alternate-gothic';
	font-size: 18px;
	line-height: 24px;
}

.new-design #tasty-treats-new strong,
.new-design #tasty-treats-new .section-badge p.text-orange.h3 {
	font-family: 'alternate-gothic-bold';
}

.new-design .tasty-treats .section-badge p.text-orange {
	font-size: 30px!important;
}

.new-design .tasty-treats p.text-gray {
	color: #A2AAAD!important;
	font-size: 22px!important;
	line-height: 24px!important;
	font-family: 'alternate-gothic'!important;
}

.new-design #tasty-treats-new h2 {
	font-family: 'dharma-gothic';
	font-size: 50px;
}

.new-design #tasty-treats-new li p:not(.text-orange) {
	color: #343A40;
}

.new-design .tasty-treats .section-bagde p.text-orange.h3 {
	font-family: 'alternate-gothic-bold';
	font-size: 30px;
}

@media screen and (min-width: 1200px) {
	.new-design .tasty-treats .column-content> .new-section-badge {
		position: absolute!important;
		bottom: 160px!important;
		left: -90px!important;
	}
}

@media (min-width: 991px) and (max-width: 1199px) {
	.new-design .tasty-treats .column-content> .new-section-badge {
		position: absolute!important;
		bottom: 0!important;
		left: -90px!important;
	}
}

@media (min-width: 769px) and (max-width: 990px) {
	.new-design .tasty-treats .column-content>.new-section-badge {
		position: absolute !important;
		bottom: -50px !important;
		left: -90px !important;
	}
}

.new-design #tasty-treats-new li p {
	font-family: 'alternate-gothic-bold';
	font-size: 26px;
}

.new-design .capture-section {
	background: url('/assets/img/in-the-box/ready-to-join-banner-bg-m2.png') no-repeat 0 0;
	background-size: cover;
	padding: 40px 0;
	position: relative;
}

.new-design .capture-section:after {
	content: '';
	position: absolute;
	left: 0;
	bottom: 0;
	background: url('/assets/img/custom-border.png') no-repeat 0 0;
	background-size: cover;
	height: 17px;
	width: 100%;
}

.new-design .capture-section .img-block .column-content {
	position: absolute;
	bottom: -40px;
}

.new-design .capture-section .button-group {
	width: 45%;
}

.new-design .capture-section .button-group a {
	border-radius: 2px;
	margin: 0;
	padding: 8px 20px;
	font-size: 20px;
	font-family: 'alternate-gothic-bold';
	letter-spacing: 1px;
	background-color: #EB6416;
	border-color: #EB6416;
}

.new-design .capture-section .button-group a:hover,
.new-design .capture-section .button-group a:focus {
	background: none;
}

.new-design #capture {
	border: none!important;
}

.new-design #capture img {
	transform: none!important;
	height: 310px;
}

.new-design #capture .column-content.container {
	max-width: 490px;
}

.new-design #capture h2 {
	font-family: 'dharma-gothic';
	font-size: 50px;
	line-height: 60px;
	letter-spacing: 0.8px;
}

/* Common */
.new-design .icon-list {
	list-style: none;
}

.new-design .icon-list>li {
	padding-left: 60px;
	position: relative;
}

.new-design #chewers-unite .top-icon {
	top: 0px;
	left: 0px;
}

.new-design #chewers-unite .section-header {
	padding-left: 150px;
}

/* toys block section */
.new-design .toys-block .section-title-content {
	margin-bottom: 90px;
	position: relative;
}

.new-design .toys-block .section-blocks {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
}

.new-design .toys-block .section-blocks .block-item {
	margin-bottom: 40px;
	width: 48%;
}

.new-design .toys-block .section-title-content .content-wrapper {
	max-width: 580px;
	margin-left: 50%;
	transform: translateX(-50%);
	margin-bottom: 100px;
}

.new-design .toys-block .section-title-content h2,
.new-design .toys-block .section-title-content p {
	color: #343A40
}

.new-design .toys-block .section-title-content h2 {
	font-size: 50px;
}

.new-design .toys-block .section-title-content h2 span {
	color: #56ABAD;
}

.new-design .toys-block .section-title-content p {
	font-family: 'alternate-gothic';
	font-size: 18px;
	line-height: 24px;
}

.new-design .toys-block .section-title-content .img-block {
	position: absolute;
	right: 0;
	top: 0;
}

.new-design .toys-block .section-title-content .img-block img {
	width: 150px;
}

@media screen and (max-width:1300px) {
	.new-design .hero-page.hero-in-the-box .image-block {
		right: 0;
	}
}

@media (min-width:1200px) and (max-width:1280px) {
	.new-design .tough-toys-extended {
		height: 1050px;
	}

	.new-design .tough-toys-extended>.container {
		max-width: 1000px;
	}

	.new-design #tough-toys-extended .image-frame img {
		height: 220px;
	}
}

@media (min-width:992px) and (max-width:1199px) {
	.hero-page.hero-in-the-box .image-block img {
		max-width: 750px;
	}

	.new-design .tough-toys-extended {
		height: 960px;
	}

	.new-design .tough-toys-extended>.container {
		max-width: 920px;
	}

	.new-design #tough-toys-extended .image-frame img {
		height: 190px;
	}

	.new-design #page-navigation .section-nav>li {
		padding: 35px 25px;
	}
}

@media screen and (max-width:991px) {
	.new-design .hero-page.hero-in-the-box .section-content {
		padding-left: 0;
	}

	.new-design #capture img {
		height: auto;
	}

	.new-design #tough-toys-extended {
		height: 1050px;
		padding-top: 70px!important;
		padding-bottom: 50px!important;
	}

	.new-design #tough-toys-extended .col-lg-4 {
		width: auto;
	}

	.new-design #tough-toys-extended .image-frame {
		margin-bottom: 20px;
	}

	.new-design #tough-toys-extended .image-frame img {
		height: 210px;
	}

	.new-design #tough-toys-extended .section-content {
		margin-top: 20px;
	}

	.new-design .capture-section .button-group {
		width: 60%;
	}
}

@media (min-width: 769px) and (max-width: 991px) {
	.new-design #tough-toys-extended .image-frame img {
		height: 160px;
	}

	.new-design .tough-toys-extended>.container {
		max-width: 740px;
	}

	.new-design #tough-toys-extended .row:first-child .image-frame.rotate-acw,
	.new-design #tough-toys-extended .row:first-child .image-frame.rotate-cw {
		top: 0;
	}

	.new-design #tough-toys-extended .row:first-child .image-frame.rotate-cw,
	.new-design #tough-toys-extended .row:last-child .image-frame.rotate-acw {
		left: 90px;
	}

	.new-design #tough-toys-extended .image-frame.rotate-0 {
		left: 80%;
		top: -120px;
	}

	.new-design #page-navigation .section-nav>li {
		padding: 35px 20px!important;
	}

	.new-design #page-navigation .section-nav>li a {
		font-size: 23px;
	}
}

@media screen and (max-width: 768px) {
	.new-design #hero-section.hero-page {
		padding-top: 60px;
		padding-bottom: 40px;
		text-align: center;
		background-size: cover;
		background-position: bottom center;
		background: linear-gradient(0deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 1) 0%, rgba(213, 213, 213, 1) 0%, rgba(255, 255, 255, 1) 44%);
	}

	.new-design .hero-page.hero-in-the-box .image-block {
		display: none;
	}

	/* .new-design .hero-page.hero-in-the-box .section-content img.img-fluid {
		max-width: 250px;
	} */

	.new-design .hero-page.hero-in-the-box .h4 {
		font-size: 20px;
	}

	.new-design #hero-section.hero-page h1 {
		font-size: 45px;
	}

	.new-design #hero-section.hero-page .icon-list {
		max-width: 300px;
		margin-left: auto;
		margin-right: auto;
	}

	.new-design #hero-section.hero-page .box-options {
		margin-left: auto;
		margin-right: auto;
		max-width: 228px;
	}

	.new-design #hero-section.hero-page li {
		margin-bottom: 30px !important;
	}

	.new-design .introduction-section .btn {
		padding: 7px 26px;
	}

	.new-design .introduction-section p br {
		display: none;
	}

	.new-design .introduction-section .orange-btn {
		margin-right: 20px;
	}

	.new-design #introduction {
		background-image: none!important;
		padding-top: 64px;
		padding-bottom: 160px;
		position: relative;
	}

	.new-design #introduction:before {
		content: '';
		position: absolute;
		left: 50%;
		z-index: -1;
		transform: translateX(-50%);
		bottom: 80px;
		background-image: url('/assets/img/in-the-box/DogBall.png');
		background-size: contain;
		background-repeat: no-repeat;
		background-position: center bottom;
		height: 338px;
		width: 345px;
	}

	.new-design #introduction h2 {
		font-size: 30px;
		margin-bottom: 30px !important;
	}

	.new-design #introduction .lead {
		margin-bottom: 180px!important;
		max-width: 335px;
		margin: 0 auto;
		padding-bottom: 35px;
	}

	.new-design #introduction .dog-left,
	.new-design #introduction .dog-right {
		display: none;
	}

	.new-design #page-navigation {
		padding-bottom: 30px;
		padding-top: 40px;
	}

	.new-design #page-navigation .section-nav {
		text-align: center;
		width: 100%;
		overflow-x: auto;
		overflow-y: hidden;
		white-space: nowrap;
	}

	.new-design #page-navigation .section-nav>li {
		padding-top: 20px;
		padding-bottom: 20px;
	}

	.new-design #page-navigation .select-block {
		background: transparent;
		color: white;
		border: none;
		display: block;
	}

	.new-design .page-navigation .btn {
		padding: 10px 33px;
	}

	.new-design #themed-boxes h3 {
		max-width: 200px;
		margin-left: auto;
		margin-right: auto
	}

	.new-design #overview-new {
		padding-top: 30px;
		padding-bottom: 30px;
	}

	.new-design #overview-new iframe {
		height: 300px;
	}

	.new-design #tough-toys>.container {
		padding-left: 0px;
		padding-right: 0px;
	}

	.new-design #tough-toys-extended {
		height: 958px;
	}

	.new-design #tough-toys-extended .section-title {
		font-size: 30px;
	}

	.new-design .tough-toys-extended>.container {
		max-width: 350px;
		margin: 0 auto;
	}

	.new-design #tough-toys-extended .row:first-child .image-frame.rotate-cw,
	.new-design #tough-toys-extended .row:last-child .image-frame.rotate-acw {
		display: none;
	}

	.new-design #tough-toys-extended .show-sm-1,
	.new-design #tough-toys-extended .show-sm-2,
	.new-design #tough-toys-extended .show-sm-3 {
		display: block;
	}

	.new-design #tough-toys-extended .show-sm-1 {
		transform: scale(.8) rotate(-8deg) !important;
		z-index: 10;
		position: relative;
	}

	.new-design #tough-toys-extended .show-sm-2 {
		transform: scale(.8) !important;
	}

	.new-design #tough-toys-extended .show-sm-3 {
		transform: scale(.8) rotate(8deg) !important;
	}

	.new-design #tough-toys-extended .show-sm-2,
	.new-design #tough-toys-extended .show-sm-3 {
		margin-top: -120px
	}

	.new-design #tough-toys-extended .image-frame p {
		font-family: 'alternate-gothic';
		font-size: 24px;
	}

	.new-design #tough-toys-extended .row:first-child .image-frame.rotate-acw {
		top: -30px;
		left: 0;
		z-index: 1;
	}

	.new-design #tough-toys-extended .image-frame.rotate-0 {
		top: 0;
		left: 20%;
	}

	.new-design #tough-toys-extended .row:last-child .image-frame.rotate-cw {
		top: 0;
		left: 0;
	}

	.new-design #tasty-treats-new .icon-list {
		max-width: 300px;
		margin-left: auto;
		margin-right: auto;
	}

	.new-design #tasty-treats-new .section-bagde {
		text-align: center;
		margin-left: 0px;
	}

	.new-design #capture .column-content {
		text-align: center;
	}

	.new-design #capture .column-content h2 {
		margin-top: 120px;
	}

	.new-design #chewers-unite .section-header {
		padding-left: 116px;
	}

	.new-design #chewers-unite {
		padding-bottom: 40px;
	}

	.new-design .toys-block {
		padding-top: 50px;
	}

	.new-design .toys-block .section-title-content h2 {
		font-size: 30px;
	}

	.new-design .toys-block .section-title-content .img-block {
		position: relative;
	}

	.new-design .toys-block .section-title-content .content-wrapper {
		max-width: 280px;
		margin: 0 auto 10px auto;
		transform: translateX(0);
	}

	.new-design .toys-block .section-title-content .img-block img {
		width: 100px;
	}

	.new-design #capture {
		padding: 45px 0 0 0;
	}

	.new-design #capture .content-block {
		margin-bottom: 50px;
	}

	.new-design #capture .column-content h2 {
		font-size: 30px!important;
		line-height: 36px;
		margin-top: 0!important;
		text-align: left;
	}

	.new-design .capture-section .img-block .column-content {
		bottom: 0;
	}

	.new-design #capture .button-group a {
		font-size: 12px;
		height: auto;
		padding: 5px;
	}

	.new-design .capture-section .row {
		flex-wrap: inherit;
	}
}

/* tablet styles */
@media (min-width: 768px) and (max-width: 992px) {
	.new-design .hero-page.hero-in-the-box .desktop-img {
		display: none;
	}

	.new-design .hero-page.hero-in-the-box .image-block {
		bottom: -140px;
	}

	.new-design .hero-page.hero-in-the-box .image-block img:last-child {
		width: 480px
	}

	.new-design #introduction {
		padding-top: 80px;
	}

	.new-design #introduction .section-content {
		margin-bottom: 100px;
	}

	.new-design #introduction .dog-right {
		right: 0!important;
		bottom: -100px !important;
		width: 195px !important;
	}

	/* .new-design #introduction .dog-left {
		height: 340px !important;
	} */
}

@media (min-width:475px) and (max-width: 768px) {
	.new-design .toy-holder {
		display: grid;
		grid-template-columns: 1fr 1fr;
		margin-bottom: 60px;
	}

	.new-design .toy-holder .toy-header {
		padding-top: 10px !important;
	}

	.new-design .toy-holder .toy-header h3,
	.new-design .toy-holder .toy-header p {
		padding-left: 20px;
		text-align: left;
	}

	.new-design .toys-block {
		margin: auto 30px;
	}
}
