@import "~@angular/material/prebuilt-themes/indigo-pink.css";

/* For Fancy Text */
@import url('https://fonts.googleapis.com/css2?family=Covered+By+Your+Grace&display=swap');
/* @import "~@angular/material/prebuilt-themes/pink-bluegrey.css"; */
/* You can add global styles to this file, and also import other style files */

/*
Orange: #f37027
Orange: #ff6c17

font-family: 'Oswald', sans-serif;
font-family: 'Merriweather', serif;

*/

:root{
  --section-border-bottom:#da5c14;
  --bullymake-gurantee-and-sponsored-bg:#ff6c17;
  --offer-toast-bg:#ff6c17;
}

body,
html {
  min-width: 360px;
}

/* Colors */
.bg-primary {
  background-color: #FF6C17 !important;
}

.bg-green {
  background-color: #00c095 !important;
}

.bg-grey {
  background-color: #f0f1f3;
}

.text-primary {
  color: #FF6C17 !important;
}

.text-teal {
  color: #009a99;
}

/* Typography */
.fw-semibold,
.font-weight-semibold {
  font-weight: 500 !important;
}

.fw-bold {
  font-weight: 800 !important;
}


/* Buttons */
.btn {
  font-family: 'alternate-gothic', sans-serif;
  text-transform: uppercase;
}

.btn-primary {
  background-color: #FF6C17 !important;
  border-color: #FF6C17;
}

/* Helpers */
.pointer {
  cursor: pointer;
}

button[disabled] {
  cursor: not-allowed;
}

/* Global Styles */
body {
  font-family: 'alternate-gothic', serif;
  font-size: 1.1rem;
  letter-spacing: 0.8px;
  color: #343A40;
}

a {
  color: #ff6c17;
}

/* Navbar */
.header-over .navbar {
  position: absolute;
  width: 100%;
  background: none !important;
  border-bottom: solid 1px rgba(255, 255, 255, 0.16);
}

@media screen and (max-width:767px) {
  .header-over .navbar {
    background-color: rgba(255, 108, 23, 0.9) !important;
  }
}


/* Sections */
.section-item {
  padding-top: 50px;
  padding-bottom: 50px;
  background-size: cover;
}

.section-title {
  margin-bottom: 3rem;
}

.mh-lg {
  min-height: 600px;
}

.bg-orange {
  background-color: #ff6c17;
}

.bg-black {
  background-color: #000;
}

/* Typography */
h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6,
.font-primary {
  font-family: 'dharma-gothic', sans-serif;
  letter-spacing: 0.8px;
}

h1,
.h1 {
	font-size: 70px;
}

h2,
.h2 {
	font-size: 50px;
}

h3,
.h3 {
	font-size: 40px;
}

h4,
.h4 {
	font-size: 30px;
}

@media screen and (max-width: 768px) {

	h1,
	.h1 {
		font-size: 45px;
		line-height: 48px;
	}

	h2,
	.h2 {
		font-size: 30px;
		line-height: 36px;
	}

	h3,
	.h3 {
		font-size: 24px;
		line-height: 28px;
	}

	h3,
	.h3 {
		font-size: 20px;
		line-height: 22px;
	}
}

@media screen and (min-width: 992px) {
	.mt-header {
		margin-top: 50px;
	}
}

.sm-lead {
  font-size: 1.2em;
}

.font-secondary {
  font-family: 'alternate-gothic', serif;
}

.text-orange {
  color: #ff6c17;
}

.text-pink {
  color: #eb667f;
}

.text-green {
  color: #48bd98;
}

.bg-teal {
	background-color: #41B38F;
}

/* Buttons */
.btn-sm {
  padding: 0.25rem 1.5rem;
}

.btn {
  background-color: #ff6c17;
  border: solid 2px #ff6c17;
  color: #fff;
  border-radius: 0;
  font-size: 20px;
  letter-spacing: 0.8px;
}

.btn-join-now {
  font-size: 1.4rem;
  padding: .5rem 3.5rem;
}


.btn-lg {
  padding: .8rem 2.5rem;
}

.btn:hover {
  background: none;
  color: #ff6c17;
}

.btn.btn-white {
  background-color: #fff;
  color: #ff6c17;
  border-color: #fff;
}

.btn.btn-white:hover {
  color: #fff;
  background: none;
}

.btn.btn-white.btn-filled:hover {
  color: #ff6c17;
  background: none !important;
}

.btn.btn-teal {
  background-color: #48bd98;
  border-color: #48bd98;
}

.btn.btn-teal:hover {
  color: #48bd98;
  background: none;
}

.btn.btn-dark {
  background-color: #000;
  border-color: #000;
}

.btn.btn-dark:hover {
  color: #000;
  background: none;
}

.btn.btn-bordered {
  background: #fff;
  border-color: #ff6c17;
  color: #f37027;
}

.btn.btn-bordered:hover {
  background: #f37027;
  border-color: #f37027;
  color: #fff;
  ;
}

/* Module: Thumbnail */
.bm-thumb {
  /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#fe8c00+0,f83600+100 */
  background: #fe8c00;
  background: -moz-linear-gradient(left, #fe8c00 0%, #f83600 100%);
  background: -webkit-linear-gradient(left, #fe8c00 0%, #f83600 100%);
  background: linear-gradient(to right, #fe8c00 0%, #f83600 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#fe8c00', endColorstr='#f83600', GradientType=1);
  background: #ff6c17;
  margin-bottom: 30px;
}

.bm-thumb .thumb-content:before,
.bm-thumb .thumb-content:after {
  background-position: center;
  background-size: cover;
  content: '';
  display: block;
  position: absolute;
  top: 5px;
  bottom: 5px;
  left: 5px;
  right: 5px;
  z-index: -1;
  opacity: .3;
}

.bm-thumb .thumb-content:after {
  background: #fff;
  opacity: 0;
  z-index: -2;
}

.bm-thumb .thumb-content {
  padding-top: 150px;
  padding-bottom: 150px;
  display: block;
  position: relative;
  z-index: 2;
}

.bm-thumb .thumb-content:hover {
  text-decoration: none;
  background: #000;
}

.bm-thumb .thumb-content:hover * {
  color: #000;
}

.bm-thumb .thumb-content:hover .thumb-price:before,
.bm-thumb .thumb-content:hover .thumb-price:after {
  background: #000;
}

.bm-thumb .thumb-content:hover:before {
  opacity: .3;
}

.bm-thumb .thumb-content:hover:after {
  opacity: 1;
}

.bm-thumb .thumb-title {
  font-size: 3.5rem;
  text-transform: uppercase;
}

.bm-thumb .thumb-price {
  font-size: 1.3rem;
}

.bm-thumb .thumb-price:before,
.bm-thumb .thumb-price:after {
  content: '';
  display: inline-block;
  width: 50px;
  height: 1px;
  background: #fff;
  top: -5px;
  position: relative;
}

.bm-thumb .thumb-price:before {
  margin-right: 10px;
}

.bm-thumb .thumb-price:after {
  margin-left: 10px;
}

@media screen and (max-width:767px) {
  .bm-thumb .thumb-content {
    padding-top: 75px;
    padding-bottom: 75px;
  }
}

/* gallery */
.gallery {
  padding-left: 0;
  margin-left: -3px;
  margin-right: -3px;
}

.gallery li {
  list-style: none;
  width: 33.3%;
  display: inline-block;
  padding: 3px;

}

.gallery li img {
  max-width: 100%;
  width: auto;

}

/* Accordion */
accordion .card {
  border-radius: 0;
  border: none;
}

accordion .btn {
  background: none;
  border: none;
  color: #000;
  font-size: 1.1rem;
  display: block;
  width: 100%;
  text-align: left;
}

accordion .btn:before {
  content: '+';
  display: inline-block;
  font-size: 1.4rem;
  margin-right: 10px;
}

accordion .accordion-toggle[aria-expanded=true] .btn:before {
  content: '-';
}

accordion .panel-body {
  font-size: 1rem;
}

accordion .btn:hover,
accordion .btn:focus {
  text-decoration: none;
  color: #000;
}

accordion .card-header {
  background: none;
  border-radius: 0 !important;
}

accordion .card {
  background-color: unset !important;
}

accordion.type-2 .card-header {
  background: #ff6c17;
  padding: .8rem;
}

accordion.type-2 .panel {
  background-color: #fff;
}

accordion.type-2 .btn {
  background: none;
  color: #fff;
  font-size: .8rem;
  padding: 0;
}

accordion.type-2 .panel-open .card-header {
  background: #000;
}

accordion.type-2 .panel-open .btn {
  color: #fff;
}

accordion.type-2 .btn:hover,
accordion.type-2 .btn:focus {
  color: #fff;
}

/* Carousel */
carousel.type2 .carousel {
  margin-bottom: 30px;
  margin-top: 20px;
}

carousel.type2 .carousel-indicators {
  bottom: -50px;
}

carousel.type2 .carousel-indicators li {
  height: 24px;
  width: 4px;
  border-radius: 7px;
  background-color: #000;
}

carousel.type2 .carousel-indicators li.active {
  background-color: #ff6c17
}

/* Process Status */
.process-status {
  position: relative;
  padding-top: 100px;
}

.process-status .status-item {
  position: relative;
  width: 80px;
}

.process-status::before {
  content: '';
  display: block;
  position: absolute;
  width: 100%;
  height: 8px;
  background: #fff;
  border-radius: 10px;
}

.status-item::before {
  content: '';
  display: block;
  width: 18px;
  height: 18px;
  background: #fff;
  border-radius: 10px;
  top: -5px;
  left: 0;
  right: 0;
  margin: auto;
  position: relative;
}

.process-status .indicator {
  width: 100%;
  position: absolute;
  top: -85px;
}

accordion.type-2 .btn:hover {
  color: #fff;
}

accordion.type-2 .panel {
  background-color: #fff;
}

accordion .btn:focus,
accordion .panel-open .btn {
  color: #ff6c17;
}

.slider-light .mat-slider-track-background {
  background: #eaeaea !important;
}

.mat-slide-toggle-content {
  font-family: 'Merriweather', serif;
}

/* .mat-checkbox-inner-container { border:1px solid !important; } */

.border {
  border-radius: 100px;
}


/* .mat-form-field-appearance-legacy .mat-form-field-underline { background-color: rgba(0,0,0,.42); }
.mat-form-field-appearance-legacy.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label { color: rgba(0,0,0,.42); } */

.more-info-form .mat-form-field-appearance-outline .mat-form-field-outline,
.more-info-form .mat-form-field-appearance-outline .mat-form-field-outline-thick {
	color: #979797 !important;
}

.more-info-form label.mat-form-field-label {
  font-family: 'alternate-gothic-bold';
	font-size: 20px;
	letter-spacing: 0.8px;
}

.more-info-form .mat-select-value,
.more-info-form .mat-form-field-label,
.more-info-form .mat-select-arrow {
	color: #343A40!important;
  font-size: 18px;
	font-family: 'alternate-gothic';
}

.more-info-form .mat-form-field-appearance-outline .mat-form-field-infix {
  padding: 15px 0 !important;
  font-family: 'alternate-gothic';
  font-size: 18px;
}


@media screen and (max-width: 992px) {
  .checkout-filter-options .mat-select-value,
  .checkout-filter-options .mat-select-placeholder {
      color: #ffffff !important;
      max-width: 100% !important;
      height: fit-content !important;
  }

  .checkout-filter-options .mobile-filter {
      height: 100%;
      align-items: center;
  }

  .checkout-filter-options .mobile-filter .mat-select-trigger {
      display: flex;
      flex-direction: row-reverse;
  }

	.checkout-filter-options .mat-select-arrow {
		color: #ffffff !important;
		border: none;
		font-style: normal;
		font-variant: normal;
		text-rendering: auto;
		line-height: 1;
		font-size: 25px;
		font-family: "Font Awesome 5 Free";
		font-weight: 600;
		height: 18px;
    margin-right: 25px;
	}

	.checkout-filter-options .mat-select-arrow::before {
		content: "\f107";
	}
}


/* Ajax */
.loading-view {
  opacity: .2;
}


/* Modal */
/* .mat-dialog-container { padding: 10px !important; } */

.upsell-dialog-panel .mat-dialog-container {
  background-color: #fff;
  z-index: 1;
  position: relative;
  padding: 0px;
  /* overflow: hidden; */
}

.upsell-dialog-panel .mat-dialog-actions {
  margin-bottom: 0px;
}

/* .upsell-dialog-panel .mat-dialog-container:before {
  content: '';
  position: absolute;
  right: 0px;
  top: 0px;
  bottom: 0px;
  width: 50%;
  background: #ff6c17;
  background-image: url('assets/img/Martini-1.png');
  background-position: right bottom;
  background-repeat: no-repeat;
} */

.upsell-dialog-panel .btn {
  min-width: 170px;
}

.upsell-dialog-panel .row {
  margin-left: 0px;
  margin-right: 0px;
}

/* .col-md-6 {
  padding-left: 0;
  padding-right: 0;
} */
@media screen and (min-width:768px) {
  .upsell-dialog-panel .row {
    max-width: 900px;
  }

  .upsell-dialog-panel .upsell-dialog {
    padding-top: 20px;
    padding-left: 50px;
    padding-right: 50px;
    padding-bottom: 20px;
  }

  .upsell-dialog-panel .mat-dialog-container {
    /* overflow: hidden; */
  }
}

@media screen and (max-width:767px) {
  .cdk-overlay-pane.upsell-dialog-panel {
    max-width: 90vw !important;
    height: 90vh !important;
  }

  .cdk-overlay-pane.upsell-dialog-panel .upsell-dialog {
    max-width: 480px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 0px;
    padding-right: 0px;
  }

  .cdk-overlay-pane.upsell-dialog-panel .upsell-dialog h2 {
    font-size: 1.8em;
  }

  .upsell-dialog-panel .mat-dialog-container:before {
    left: 0px;
    bottom: auto;
    width: 100%;
    height: 30%;
    background-position: right bottom;
    background-size: 100%;
  }

}


/* CTA Box */
.cta-bordered-box {
  border: solid 2px #ff6c17;
  border-radius: 5px;
  padding: 40px;
  background: #fff;
  text-align: center;
  max-width: 850px;
  margin: 0 auto;
}

.cta-bordered-box .lead {
  font-size: 1.8em;
}

@media screen and (max-width:767px) {

  /* body.single-product .zopim[data-test-id="ChatWidgetMobileButton"] { */
  body.single-product .zopim {
    bottom: 125px !important;
    z-index: 1 !important;
  }
}



.form-group {
  margin-bottom: 0px;
}

body.checkout #attentive_overlay,
body.checkout #launcher {
  display: none !important;
}

@media screen and (max-width:767px) {

  body.single-product #attentive_overlay,
  body.single-product #launcher {
    display: none !important;
  }
}











#testimonialCarousel .indicators a {
  text-decoration: none;
}

#testimonialCarousel .indicators img {
  border: solid 10px #e7e7e7;
  border-radius: 100%;

}



.carousel-wrapper.style-1 .carousel .carousel-control-next,
.carousel-wrapper.style-1 .carousel .carousel-control-prev {
  background: #f4f4f4;
  opacity: 1;
  width: 50px;
  height: 120px;
  margin: auto;
  border-radius: 5px;
  cursor: pointer
}

.carousel-wrapper.style-1 .carousel .carousel-control-next {
  right: -80px;
}

.carousel-wrapper.style-1 .carousel .carousel-control-prev {
  left: -80px;
}

#testimonialCarousel .carousel-item {
  padding-left: 15%;
  padding-right: 15%;
}

.carousel-wrapper.style-1 .carousel-indicators li {
  width: 100px;
  height: 100px;
  border-radius: 100%;
  background-size: 101%;
  background-position: center center;
  border: solid 7px #f4f4f4;
}

.carousel-wrapper.style-1 .carousel-indicators li.active {
  box-shadow: 0px 0px 0px 1px #FF6C17;
}

#testimonialCarousel .carousel-indicators li:nth-child(1) {
  background-image: url(./assets/img/author-dog-1.png);
}

#testimonialCarousel .carousel-indicators li:nth-child(2) {
  background-image: url(./assets/img/author-dog-2.png);
}

#testimonialCarousel .carousel-indicators li:nth-child(3) {
  background-image: url(./assets/img/author-dog-3.png);
}

#testimonialCarousel .carousel {
  padding-bottom: 150px;
}

#instagramCarousel.carousel-wrapper.style-1 .carousel .carousel-control-next,
#instagramCarousel.carousel-wrapper.style-1 .carousel .carousel-control-prev,
#toysSliderCarousel.carousel-wrapper.style-1 .carousel .carousel-control-next,
#toysSliderCarousel.carousel-wrapper.style-1 .carousel .carousel-control-prev {
  background: rgb(225 225 225 / 77%);
}

@media screen and (max-width: 767px) {

  .carousel-wrapper.style-1 .carousel .carousel-control-next {
    right: -0px;
  }

  .carousel-wrapper.style-1 .carousel .carousel-control-prev {
    left: -0px;
  }

  .carousel-wrapper.style-1 .carousel-indicators li {
    width: 70px;
    height: 70px;
  }

  #testimonialCarousel .carousel-item {
    padding-left: 0%;
    padding-right: 0%;
  }

  #testimonialCarousel .indicators img {
    width: 80px;
    border-width: 5px;
  }

  #testimonialCarousel .carousel-control-prev,
  #testimonialCarousel .carousel-control-next {
    display: none;
  }

  #instagramCarousel.carousel-wrapper.style-1 .carousel .carousel-control-next {
    right: 8px;
  }

  #instagramCarousel.carousel-wrapper.style-1 .carousel .carousel-control-prev {
    left: 8px;
  }

  #instagramCarousel.carousel-wrapper.style-1 .carousel .carousel-control-next,
  #instagramCarousel.carousel-wrapper.style-1 .carousel .carousel-control-prev,
  #toysSliderCarousel.carousel-wrapper.style-1 .carousel .carousel-control-next,
  #toysSliderCarousel.carousel-wrapper.style-1 .carousel .carousel-control-prev {
    width: 40px;
    height: 80px;
  }





  body.header-fixed {
    padding-top: 64px;
  }

  body.header-fixed app-header nav.navbar {
    position: fixed;
    top: 0px;
    left: 0px;
    right: 0px;
  }

}

.post-checkout-upsell-dialog {
  background-image: url('assets/img/popup-bg.jpg');
  background-color: #fff;
  background-size: 100% 100%;
  overflow-y: scroll;
  max-width: 100vw !important;
}

.post-checkout-upsell-dialog .mat-dialog-container {
  background: none;
}

@media screen and (max-width: 767px) {
  .post-checkout-upsell-dialog {
    height: 100%;
  }
}

.mobile-carousel>.flex-nowrap {
  overflow-x: auto;
  white-space: nowrap;
}

.mobile-carousel>.flex-nowrap>.slide-item {
  display: inline-block;
  float: none;
  width: 70%;
  margin-right: 30px;
}

.mobile-carousel>.flex-nowrap>.slide-item:last-child {
  margin-right: 0px;
}




#toysSlider .container-sm {
  max-width: 750px;
  margin-left: auto;
  margin-right: auto;
}

#toysSlider {
  margin-bottom: 80px;
}

#toysSlider #cta {
  margin-bottom: -120px;
  margin-top: 30px;
  ;
}

@media screen and (max-width: 991px) {
  #toysSlider #cta li {
    /* width: 100%; */
    margin-left: 8px;
    margin-right: 8px;
    vertical-align: top;
    /* font-size: 1.2rem; */
  }

  #toysSlider #cta li:nth-child(1):after,
  #toysSlider #cta li:nth-child(2):after,
  #toysSlider #cta li:nth-child(3):after {
    content: '';
    display: inline-block;
    width: 8px;
    height: 2px;
    border-radius: 20px;
    background: #ffffff;
    vertical-align: middle;
    margin-left: 15px
  }

  /* #toysSlider #cta li:nth-last-child(2):after,
  #toysSlider #cta li:nth-last-child(3):after {
    content: none;
  } */
  #toysSlider #cta li:first-child {
    margin-left: 0px;
    ;
  }

  #toysSlider #cta li:last-child {
    margin-right: 0px;
    ;
  }
}



/* Header */
app-checkout-header {
  display: none;
}

body.checkout app-checkout-header {
  display: block;
}

body.checkout app-header {
  display: none;
}


body.no-header app-header .navbar,
body.no-footer app-footer {
  display: none;
}

/* Carousal Width Fix */
/* .item.active {
  width: 100%;
} */


/* Carousel With Block Indicators */
carousel.block-controls .carousel-control {
	position: absolute;
	height: 50px;
	width: 50px;
	background-color: #F8F9FA;
	opacity: 1;
  top: auto !important;
  bottom: 0px;
  right: 0px;
  left: auto;
}
carousel.block-controls .carousel-control-prev {
  right: 60px;
}
carousel.block-controls .carousel-control-next-icon,
carousel.block-controls .carousel-control-prev-icon {
	filter:invert(0.3);
}

.hide-item {
	opacity: 0;
	position: absolute;
	height: 0;
	width: 0;
}



body.subscribe-active #topbar {
	display: none;
}


.visible-on-b {
	display: none;
}

.active-b .visible-on-a {
	display: none;
}
.active-b .visible-on-b {
	display: block;
}

/* app-home app-current-box-landing,
.active-b app-home app-current-box-landing-v2 {
	display: block;
}
app-home app-current-box-landing-v2,
.active-b app-home app-current-box-landing {
	display: none;
}
.active-b app-header #topbar {
	display: none;
} */

#topbar {
	display: none;
}

/* .active-aggressive-chewing-dog-toys #topbar,
.active-dog-gift #topbar,
.active-dog-toy #topbar,
.active-dog-toy-b #topbar,
.active-dog-toy-b2 #topbar,
.active-dog-toy-p #topbar,
.active-how-this-dog-company-changed-the-game-for-power-chewing-dogs #topbar,
.active-product-1-month-subscription #topbar,
.active-product-1-month-subscription-promotion #topbar,
.active-product-1-month-subscription-promotion-b #topbar,
.active-product-1-month-subscription-promotion-fb20 #topbar,
.active-product-1-year-subscription #topbar,
.active-product-3-month-subscription #topbar,
.active-subscription-box #topbar,
.active-subscription-box-20 #topbar,
.active-subscription-box-b #topbar,
.active-black-friday #topbar {
	display: none !important;
} */

.active- #topbar,
.active-in-the-box #topbar,
.active-faq #topbar,
.active-past-boxes #topbar,
.active-reviews #topbar,
.active-contact-us #topbar {
	display: block !important;
}



.active-aggressive-chewing-dog-toys app-offer-toast,
.active-dog-gift app-offer-toast,
.active-dog-toy app-offer-toast,
.active-dog-toy-b app-offer-toast,
.active-dog-toy-b2 app-offer-toast,
.active-dog-toy-p app-offer-toast,
.active-how-this-dog-company-changed-the-game-for-power-chewing-dogs app-offer-toast,
.active-product-1-month-subscription app-offer-toast,
.active-product-1-month-subscription-promotion app-offer-toast,
.active-product-1-month-subscription-promotion-b app-offer-toast,
.active-product-1-month-subscription-promotion-fb20 app-offer-toast,
.active-product-1-year-subscription app-offer-toast,
.active-product-3-month-subscription app-offer-toast,
.active-subscription-box app-offer-toast,
.active-subscription-box-20 app-offer-toast,
.active-subscription-box-b app-offer-toast,
.active-black-friday app-offer-toast,
.active-my-account app-offer-toast,
.active-checkout app-offer-toast,
.active-subscribe app-offer-toast,
.active-affiliate app-offer-toast {
	display: none !important;
}

@media screen and (max-width: 768px) {
	.active- app-offer-toast {
		display: none !important;
	}
}




#secondary-topbar {
	display: none;
}

/* body.subscribe-active.subscribe-plan #secondary-topbar,
body.subscribe-active.subscribe-addons #secondary-topbar,
body.checkout #secondary-topbar {
	display: block;
} */
