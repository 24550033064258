@font-face {
	font-family: 'dharma-gothic';
	src: url('../font//dharma-gothic-p/dharma-gothic-p.woff2') format('woff2'),
		url('../font//dharma-gothic-p/dharma-gothic-p.woff') format('woff'),
		url('../font//dharma-gothic-p/dharma-gothic-p.ttf') format('truetype'),
		url('../font//dharma-gothic-p/dharma-gothic-p.svg#dharma-gothic-p') format('svg');
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'dharma-gothic-bold';
	src: url('../font//dharma-gothic-p/dharma-gothic-p-bold.woff2') format('woff2'),
		url('../font//dharma-gothic-p/dharma-gothic-p-bold.woff') format('woff'),
		url('../font//dharma-gothic-p/dharma-gothic-p-bold.ttf') format('truetype'),
		url('../font//dharma-gothic-p/dharma-gothic-p-bold.svg#dharma-gothic-p-bold') format('svg');
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'alternate-gothic';
	src: url('../font//alternate-gothic-atf/alternate-gothic-atf.woff2') format('woff2'),
		url('../font//alternate-gothic-atf/alternate-gothic-atf.woff') format('woff'),
		url('../font//alternate-gothic-atf/alternate-gothic-atf.ttf') format('truetype'),
		url('../font//alternate-gothic-atf/alternate-gothic-atf.svg#alternate-gothic-atf') format('svg');
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'alternate-gothic-bold';
	src: url('../font//alternate-gothic-atf/alternate-gothic-atf-bold.woff2') format('woff2'),
		url('../font//alternate-gothic-atf/alternate-gothic-atf-bold.woff') format('woff'),
		url('../font//alternate-gothic-atf/alternate-gothic-atf-bold.ttf') format('truetype'),
		url('../font//alternate-gothic-atf/alternate-gothic-atf-bold.svg#alternate-gothic-atf-bold') format('svg');
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}

.whats-in-the-box h2,
.how-it-works h2,
.monthly-box h3,
.toys-block h2 {
	font-family: 'dharma-gothic';
	font-size: 50px;
	letter-spacing: 0.8px;
}
